import { css } from 'styled-components';
import { media } from '~/ui/utils/media';

export const baseFont = css`
  font-family: Mulish, sans-serif;
`;

export const accentFont = css`
  font-family: 'EkoTitle', 'Philosopher', sans-serif;
`;

export type FontSize = 'xxs' | 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl';

export type FontSizeProps = { $fs?: FontSize };

type FontSizeValues = Record<FontSize, number>;

const MOBILE_FONT_SIZE: FontSizeValues = {
  xxs: 12,
  xs: 12,
  s: 14,
  m: 16,
  l: 18,
  xl: 20,
  xxl: 24,
};

export const TABLE_FONT_SIZE: FontSizeValues = {
  xxs: 12,
  xs: 14,
  s: 16,
  m: 16,
  l: 18,
  xl: 22,
  xxl: 30,
};

const DESKTOP_FONT_SIZE: FontSizeValues = {
  xxs: 14,
  xs: 16,
  s: 18,
  m: 18,
  l: 24,
  xl: 28,
  xxl: 36,
};

export const getFontSize = (fs?: FontSize) => css`
  line-height: 150%;
  font-size: ${() => (fs ? MOBILE_FONT_SIZE[fs] : MOBILE_FONT_SIZE.m)}px;

  ${media.md`
    font-size: ${fs ? TABLE_FONT_SIZE[fs] : TABLE_FONT_SIZE.m}px;
  `}

  ${media.xl`
    font-size: ${fs ? DESKTOP_FONT_SIZE[fs] : DESKTOP_FONT_SIZE.m}px;
  `}
`;

export const fontSize = css<FontSizeProps>`
  ${({ $fs }) => getFontSize($fs)};
`;
