import React, { PropsWithChildren } from 'react';

import * as S from './Tag.styled';

type Props = {
  className?: string;
  isActive: boolean;
};

export const Tag: React.FC<PropsWithChildren<Props>> = (props) => {
  return (
    <S.Container $isActive={props.isActive} className={props.className}>
      {props.isActive && <S.IconCheckMark />}
      {props.children}
    </S.Container>
  );
};
