import styled, { css } from 'styled-components';

import { fontSize } from '~/ui/kit/constants/fonts';
import { gaps, getEkoBorderRadius } from '~/ui/kit/constants/sizes';
import { SuccessIcon } from '~/ui/kit/atoms/IconSuccess';

type ActiveProps = {
  $isActive: boolean;
};

export const IconCheckMark = styled(SuccessIcon)`
  width: var(--active-icon-size);
  height: var(--active-icon-size);
  opacity: 0;
  transition: opacity 0.3s;
  position: absolute;
  left: calc((var(--tag-horizontal-padding) - var(--active-icon-size)) / 2);
`;

export const Container = styled.div.attrs<ActiveProps>({ $fs: 's' })<ActiveProps>`
  --active-icon-size: 20px;
  --tag-horizontal-padding: ${gaps.xxl}px;
  padding: ${gaps.xxs / 2}px var(--tag-horizontal-padding);
  position: relative;
  
  display: flex;
  align-items: center;
  border-radius: ${getEkoBorderRadius({ $br: 'xl' })};
  background-color: var(--eko-primary-color-light-6);
  color: var(--eko-primary-color);
  ${fontSize};
  white-space: nowrap;
  transition: outline-color .3s;
  outline: 1px solid #FFFFFF00;
  
  ${({ $isActive }) =>
    $isActive &&
    css`
      outline-color: currentColor;
      & ${IconCheckMark} {
        opacity: 1;
      }
    `}}
`;
