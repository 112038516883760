import React, { useMemo } from 'react';
import { CheckboxGroupProps } from 'antd/es/checkbox/Group';
import { pipe } from 'fp-ts/function';
import * as A from 'fp-ts/Array';

import * as S from './CheckboxSelect.styled';

type Props = {
  className?: string;
  disabled?: boolean;
} & CheckboxGroupProps;

export const CheckboxSelect: React.FC<Props> = (props) => {
  const options = useMemo(() => {
    if (Array.isArray(props.options)) {
      return pipe(
        props.options,
        A.map((option) => {
          if (typeof option === 'object') {
            return {
              ...option,
              label: <S.OptionTag isActive>{option.label}</S.OptionTag>,
            };
          }
          return option;
        }),
      );
    }
    return props.options;
  }, [props.options]);
  return <S.Container {...props} options={options} className={props.className}></S.Container>;
};
