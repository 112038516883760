import styled, { createGlobalStyle, css } from 'styled-components';

import { media } from '~/ui/utils/media';
import { Tooltip } from '~/ui/kit/atoms/Tooltip';
import { IconInfo } from '~/ui/kit/atoms/IconInfo';

export const Icon = styled(IconInfo)`
  width: 100%;
  height: 100%;
`;

export const Container = styled(Tooltip)`
  display: flex;
  align-items: center;
  color: var(--eko-fifth-color);

  opacity: 0.7;
  transition:
    color 0.3s,
    opacity 0.3s;

  &:hover {
    color: var(--eko-primary-color);
    opacity: 1;
  }
`;

const tooltipBackground = 'white';

const borderColor = '#eaeaea';

const border = css`
  border: 1px solid ${borderColor};
  box-shadow: 0 0 5px 5px var(--eko-third-color-light-2);
`;
export const TooltipStyles = createGlobalStyle<{ $desktopMaxWidth: number }>`
    .ant-tooltip {
        min-width: auto;
        --antd-arrow-background-color: ${tooltipBackground};
        z-index: 99999;
        ${({ $desktopMaxWidth }) => css`
          ${media.sm`
            max-width: ${$desktopMaxWidth}px;
          `}
        `}

        & .ant-tooltip-arrow {
            display: none;
            ${media.sm`
            display: block;
        `}
        }

        &.ant-tooltip-placement-top .ant-tooltip-arrow {
            bottom: 2px;

            &:before {
                border-top: 10px solid ${borderColor};
            }

            &:after {
                border-top: 10px solid white;
                z-index: 1;
            }
        }

        & &-inner {
            box-shadow: none;
            color: var(--eko-third-color);
            background-color: ${tooltipBackground};
            padding: 15px;
            ${border};
            border-radius: 10px;
        }
    }
`;
