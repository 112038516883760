import {
  DatePicker,
  DatePickerProps,
  FormItemProps,
  InputNumberProps,
  InputProps as AInputProps,
  SelectProps as ASelectProps,
  RadioProps as ARadioProps,
} from 'antd';
import { Dayjs } from 'dayjs';
import React from 'react';
import { BorderRadiusProps } from '~/ui/kit/constants/sizes';
import { FontProps, FontSizeProps } from '~/ui/kit/constants/fonts';

export enum InputType {
  STRING = 'STRING',
  PHONE = 'PHONE',
  NUMBER = 'NUMBER',
  DATE = 'DATE',
  RADIO = 'RADIO',
  SELECT = 'SELECT',
  CHECKBOX_SELECT = 'CHECKBOX_SELECT',
  CUSTOM = 'CUSTOM',
  TEXT_AREA = 'TEXT_AREA',
  COMPOSITE = 'COMPOSITE',
}

export type PickerProps = React.ComponentProps<typeof DatePicker>;

export type InputStyledProps = {
  $bordered?: boolean;
  $borderColor?: string;
} & BorderRadiusProps &
  FontSizeProps &
  FontProps;

export type BaseInputProps = {
  id: string;
  containerClassName?: string;
  className?: string;
  placeHolder?: string;
  label?: React.ReactNode;
  labelDescription?: string | JSX.Element;
  description?: React.ReactNode;
  Controls?: React.ReactNode | React.FC;
  required?: boolean;
  requireMessage?: string;
  validationRules?: FormItemProps['rules'];
  onKeyPress?: AInputProps['onKeyPress'];
} & InputStyledProps;

export type StringInputProps = {
  type: InputType.STRING;
  defaultValue?: string;
  onChange?: AInputProps['onChange'];
  pattern?: AInputProps['pattern'];
  htmlType?: AInputProps['type'];
};

export type PhoneInputProps = {
  type: InputType.PHONE;
  value?: string;
  onChange?: (value: string) => void;
};

export type TextAreaInputProps = {
  type: InputType.TEXT_AREA;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
};

export type NumberInputProps = {
  type: InputType.NUMBER;
  defaultValue?: number;
  min?: number;
  max?: number;
  step?: number;
  controls?: InputNumberProps['controls'];
  onChange?: InputNumberProps['onChange'];
  keyboard?: InputNumberProps['keyboard'];
  parser?: InputNumberProps['parser'];
  formatter?: InputNumberProps['formatter'];
};

export type DateInputProps = {
  type: InputType.DATE;
  defaultValue?: Dayjs;
  picker?: PickerProps['picker'];
  disabledDate?: DatePickerProps['disabledDate'];
  defaultPickerValue?: DatePickerProps['defaultPickerValue'];
  showToday?: boolean;
  onChange?: DatePickerProps['onChange'];
};

export type RadioInputProps = {
  type: InputType.RADIO;
  defaultValue?: string;
  layout?: 'horizontal' | 'vertical';
  options: { label: React.ReactNode; value: string }[];
  hideHtmlControl?: boolean;
  value?: string;
  disabled?: boolean;
  onChange?: ARadioProps['onChange'];
  className?: string;
  inputOptionType?: 'button';
  name?: string;
  hoverDescription?: {
    content: React.ReactNode;
  };
};

export type SelectProps = {
  type: InputType.SELECT;
  defaultValue?: string;
  options: { label: React.ReactNode; value: string }[];
  showSearch?: ASelectProps['showSearch'];
  before?: React.ReactNode;
  filterOption?: ASelectProps<string, { label?: React.ReactNode; value?: React.ReactNode }>['filterOption'];
  onChange?: ASelectProps['onChange'];
};

export type CheckboxSelect = {
  type: InputType.CHECKBOX_SELECT;
  options: { label: React.ReactNode; value: string | number }[];
  disabled?: boolean;
  onChange?: ASelectProps['onChange'];
};

export type CustomInputProps = {
  type: InputType.CUSTOM;
  Component: React.FC | JSX.Element;
  onChange?: VoidFunction;
};

export type InputProps = BaseInputProps &
  (
    | StringInputProps
    | PhoneInputProps
    | NumberInputProps
    | DateInputProps
    | RadioInputProps
    | CheckboxSelect
    | SelectProps
    | TextAreaInputProps
    | CustomInputProps
  );

export type CompositeInputProps = {
  id: string;
  type: InputType.COMPOSITE;
  inputs: React.ReactNode | InputProps[];
  label?: string;
  description?: React.ReactNode;
  className?: string;
};
