import React from 'react';
import { TooltipProps } from 'antd';

import * as Styled from './InfoTooltip.styled';

type InfoTooltipProps = TooltipProps & { desktopMaxWidth?: number };
export const InfoTooltip: React.FC<InfoTooltipProps> = ({ desktopMaxWidth = 450, ...props }) => {
  return (
    <Styled.Container {...props}>
      <Styled.TooltipStyles $desktopMaxWidth={desktopMaxWidth} />
      {props.children || <Styled.Icon />}
    </Styled.Container>
  );
};
