import { Checkbox } from 'antd';
import styled from 'styled-components';

import { Tag } from '~/ui/kit/molecules/Tag';
import { gaps } from '~/ui/kit/constants/sizes';

export const OptionTag = styled(Tag)``;

export const Container = styled(Checkbox.Group)`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: ${gaps.xxs}px;
}
  
  .ant-checkbox {
    opacity: 0;
    position: absolute;

    &+span {
       padding: 0;
    }
  }

  .ant-checkbox-wrapper { 
    &-disabled {
      opacity: 0.5;
    }

    &:not(&-checked) {
      ${OptionTag} {
        outline-color: transparent;

        svg {
          opacity: 0;
        }
      }
    }
    &-checked {
      ${OptionTag} {
        svg {
          opacity: 1;
        }
      }
    }
  }
`;
